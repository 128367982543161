import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Copyright from '../components/Copyright';
import Header from '../components/header';
import Content from '../components/Content';
import PageIntro from '../components/pageIntro';
import PageTitle from '../components/pageTitle';
import Card4 from '../components/Card4';
import Card3 from '../components/Card3';
import Card5 from '../components/Card5';




export default function About() {
  return (
    <Grid Container direction="column">
      <Grid item>
        <Header title="Ecrire"/>
      </Grid>
      <Grid item>
      <Container maxWidth="sm">
      <Box my={4}>
      <PageTitle/>
      <PageIntro content="Mon appétit pour formuler et écrire, sous tant de formes, correspond finalement, très exactement, à ce dont relève la création d'un outil destiné à être utilisé au quotidien" />
      <Card4 title="Espace documentaire Confluence" customer="" description="Ma propension à tout écrire trouve un intérêt tout particulier dans la mise en place et la rédaction au quotidien d'espaces documentaires, clé de voute de projets ayant vocation à durer" moreLink="" />
      <Card3 title="Pourquoi personne n'utilise l'outil qu'on vient de lancer ?" customer="" description="Dans cet article je décris le constat si souvent fait au cours de toutes ces années que trop de projets, une fois déployés, restent lettre morte. Et comment y remédier" moreLink="https://www.southside-interactive.com/mais-pourquoi-mes-collaborateurs-nutilisent-pas-le-nouvel-outil-b2e-quon-a-cree-pour-eux/" />
<Card3 title="Les chances de survie d’un nouvel outil digital en entreprise" customer="" description="Cet article témoigne également de la difficulté à faire utiliser un outil même une fois qu'il est terminé, et des différences d'un contexte d'entreprise à l'autre" moreLink="https://www.southside-interactive.com/survie-dun-nouvel-outil-digital-en-entreprise/" />
<Card4 title="Définition vocabulaire projet" customer="" description="J'ai constaté au cours de mes projets passés combien les mots qui seront utilisés tout au long du projet doivent être soigneusement choisis, et compris de façon identique par chacun. Le recours à une page de définition du vocabulaire étant le meilleur des moyens d'y parvenir" moreLink="" />
<Card3 title="Application mobile ou web app : que choisir pour une app B2B interne ?" customer="" description="Dans cet article je détaille les avantages et les inconvénients de chacune de ces deux options, qu'une bonne compréhension technique exige pour arbitrer" moreLink="https://www.southside-interactive.com/application-mobile-web-app-b2b/" />
<Card3 title="The challenge to make new with old" customer="" description="Dans cet article je décris les opportunités qu'offrent le réemploi et le recyclage, et les difficultés associées" moreLink="http://yieldconsumption.com/the-challenge-to-make-new-with-old-yield-consumption-not-quite-there-yet/" />
<Card4 title="Schémas fonctionnels" customer="" description="Dans les phases amont des projets, je prends régulièrement un crayon pour dessiner à l'aide d'icones, de flèches et de dessins très concret ce dont on besoin mes interlocuteurs pour se projeter, et décider" moreLink="" />
<Card3 title="Particle.io : un framework pour projet connecté" customer="" description="Dans cet article je détaille l'intérêt que peut avoir un framework IOT comme Particle, à toutes les étapes du développement d'un projet IOT" moreLink="https://www.southside-interactive.com/particle-io-un-framework-pour-projet-connecte/" />
<Card3 title="Sigfox et réseaux basse fréquence : un autre schéma pour objets connectés" customer="" description="Dans cet article je présente le réseaux basses fréquences et leurs promesses vis-à-vis de réseaux mobiles classiques" moreLink="https://www.southside-interactive.com/sigfox-et-reseaux-basse-frequence-un-autre-schema-pour-objets-connectes/" />
<Card3 title="La transition digitale en quelques fleurs : les Cent Fleurs" customer="" description="Cet article m'a permis d'illustrer combien le numérique offre de piste de changements et comment arbitrer pour identifier les plus porteuses" moreLink="https://www.southside-interactive.com/la-transition-digitale-en-quelques-fleurs-les-cent-fleurs/" />
<Card4 title="Schémas formulation vision" customer="" description="Avant même d'imaginer concrètement à quoi pourrait ressembler une application ou un outil, je dessine souvent des mises en situations concrètes qui permettent d'initier l'intérêt d'interlocuteur clé, et de déclencher sa mise en mouvement." moreLink="" />
<Card4 title="Schémas ergonomiques" customer="" description="Pour faire des aller-retour rapides entre conception et réalisation, je traduis en écran à main levée les idées et attentes de futurs utilisateurs, intégrant notamment les contraintes techniques dont il faut qu'ils prennent conscience, et valider de façon concrète ce qui est vital pour eux, permettant d'aboutir dans la foulée à un périmètre fonctionnel déjà confronté" moreLink="" />
<Card3 title="Comment choisir entre développement custom et SaaS ?" customer="" description="Dans cet article j'aborde cette éternelle question, toujours davantage d'actualité au fur et à mesure que les outils informatiques se complexifient" moreLink="https://www.southside-interactive.com/dev-custom-ou-saas/" />
<Card3 title="Le workshop pour confronter votre application au réel" customer="" description="Dans cet article je reprends les avantages d'un atelier pour se confronter à des utilisateurs réels, pour rentrer dans le vif du sujet au plus tôt" moreLink="https://www.southside-interactive.com/workshop-confronter-application-reel/" />
<Card3 title="Créer son site internet est un travail marketing" customer="" description="Dans cet aticle j'aborde sous l'angle du site vitrine le caractère pluri-disciplinaire d'un projet informatique, qui dépasse largement sa seule dimension technique" moreLink="https://www.southside-interactive.com/creer-son-site-internet-est-un-travail-marketing/" />
<Card3 title="L’innovation en entreprise en 2017" customer="" description="Dans cet article je présente des exemples concrets d'initiative de transformation digitale dans différentes entreprises" moreLink="https://www.southside-interactive.com/innovation-entreprise-2017-en-vrai/" />
<Card3 title="Transport et Logistique à l’heure du digital" customer="" description="Cet article est une approche sectorielle du numérique, qui m'a permis de projeter concrètement ce que le numérique change dans les quotidiens des professionnels de la logistique" moreLink="https://www.southside-interactive.com/transport-et-logistique-digitale/" />
<Card3 title="Pour un premier jet ou un prototype : avantage aux équipes réduites" customer="" description="Dans cet article je raconte comment un projet d'innovation qui doit se confronter au plus vite à son contexte d'utilisation favorise un fontionnement en petite équipe" moreLink="https://www.southside-interactive.com/une-equipe-reduite-pour-mon-prototype/" />
<Card3 title="La transition digitale en quelques fleurs : les nouveaux développeurs" customer="" description="Dans cet article je détaille ce qui caractérise les développeurs dont les entreprises ont besoin, en particulier leur capacité à rester concret et leur inter-disciplinarité" moreLink="https://www.southside-interactive.com/transition-digitale-quelques-fleurs-nouveaux-developpeurs/" />
<Card3 title="La transition digitale en quelques fleurs : le bourgeonnement digital" customer="" description="Dans cet article je témoigne de la facilité nouvelle que permettent les outils de création digitale, et la chance que cela représente pour une entreprise si elle sait arnacher ce réservoir d'amélioration" moreLink="https://www.southside-interactive.com/la-transition-digitale-en-quelques-fleurs-le-bourgeonnement-digital/" />
<Card3 title="Objets connectés en entreprise : connecter plus que des objets" customer="" description="Dans cet article je témoigne de l'enjeu de déploiement et d'adoption qui sous-tend la mise en place d'objets connectés, bien au-delà de la seule dimension technique" moreLink="https://www.southside-interactive.com/transition-digitale-le-proof-of-concept-pour-ecouter-et-comprendre/" />
<Card3 title="Transition digitale : le proof of concept pour écouter et comprendre" customer="" description="Dans cet article je présente les intérêts de fonctionner à l'aide de 'proof of concept' pour avancer concrètement malgré les contextes complexes que sont les entreprises" moreLink="https://www.southside-interactive.com/objets-connectes-entreprise/" />
<Card3 title="La sécurité informatique en clair" customer="" description="Dans cet article je récapitule le plus simplement possible les sous-jacents à la sécurité informatique" moreLink="https://www.southside-interactive.com/la-securite-informatique-en-clair/" />
<Card3 title="Mais qu’est ce qu’une API ?" customer="" description="Dans cet article je cherche à expliquer simplement le fonctionnement d'une API, et à montrer les opportunités que l'APIsation de nombreux services permet désormais" moreLink="https://www.southside-interactive.com/un-bouton-pour-connecter-le-monde-physique/" />
<Card3 title="Un bouton pour connecter le monde physique" customer="" description="Dans cet article je présente un bouton connecté et propose des façons de l'utiliser, la technologie pouvant effectivement être mise au service de multiples utilisations" moreLink="https://www.southside-interactive.com/data-et-objets-connectes/" />
<Card3 title="Data et objets connectés" customer="" description="Dans cet article je présente le potentiel des objets connectés sous l'angle des données qu'ils permettent de récupérer, et des enjeux pour les exploiter" moreLink="https://www.southside-interactive.com/particle-io-un-framework-pour-projet-connecte/" />
<Card3 title="Homekit : les objets connectés selon Apple" customer="" description="Dans cet article j'aborde le sujet des objets connectés d'un point de vue utilisateur en s'appuyant sur la vision d'Apple pour ses clients" moreLink="https://www.southside-interactive.com/homekit-les-objets-connectes-selon-apple/" />
<Card3 title="Beta-test avec Testflight : tester une application iPhone simplement" customer="" description="Cet article est un tutoria que j'ai rédigé pour que les testeurs puissent installer Testflight et accéder à la version beta d'une application avec facilité" moreLink="https://www.southside-interactive.com/beta-test-avec-testflight-tester-une-application-iphone-simplement/" />
<Card3 title="Le dialogue entre un objet connecté et une application web ou mobile" customer="" description="Dans cet article je détaille les logiques mises en place pour permettre le développement d'une application mobile communiquant en Bluetooth avec un objet connecté" moreLink="https://www.southside-interactive.com/le-dialogue-entre-un-objet-connecte-et-une-application-web-ou-mobile/" />
<Card3 title="15 applications web et mobiles utilisées en B2B" customer="" description="Dans cet article je liste toute une série d'outils digitaux qui témoignent concrètement des initiatives mises en place par les entreprises avec lesquelles nous travaillons" moreLink="https://www.southside-interactive.com/15-applications-web-et-mobiles-utilisees-en-b2b/" />
<Card3 title="Le développement d’un objet connecté : une introduction !" customer="" description="Dans cet article je récapitule le plus simplement possible les grandes lignes d'un projet IOT" moreLink="https://www.southside-interactive.com/le-developpement-dun-objet-connecte-une-introduction/" />
<Card3 title="Bien choisir la structure de son site internet" customer="" description="Dans cet article je fais le lien entre un site internet et le message qu'il veut faire passer" moreLink="https://www.southside-interactive.com/bien-choisir-la-structure-de-son-site-internet/" />
<Card3 title="Choisir les mots clés pour son site internet" customer="" description="Dans cet article je me focalise sur les mots clés, aussi important pour le référencement que pour la bonne compréhension des visiteurs" moreLink="https://www.southside-interactive.com/choisir-les-mots-cles-pour-son-site-internet/" />
<Card3 title="Qui pour référencer son site : développeur, expert SEO ou agence ?" customer="" description="Dans cet article je compare les interlocuteurs avec lesquels travailler pour créer son site" moreLink="https://www.southside-interactive.com/qui-pour-referencer-son-site-developpeur-expert-seo-ou-agence/" />
<Card3 title="Accompagner le developpement de votre présence digitale" customer="" description="Dans cet article je présente en quoi consiste l'effort de présence en ligne, et comment s'y atteler" moreLink="https://www.southside-interactive.com/accompagner-le-developpement-de-votre-presence-digitale/" />
<Card3 title="Why yield consumption schemes are about to blossom " customer="" description="Dans cet article je donne mon point de vue sur les schémas d'économie circulaire et leur généralisation" moreLink="http://yieldconsumption.com/why-yield-consumption-schemes-are-about-to-blossom/" />
<Card3 title="The yield consumption manifesto " customer="" description="Dans cet article je récapitule ma démarche !" moreLink="http://yieldconsumption.com/the-yield-consumption-manifesto/" />
<Card3 title="Eco-mobilier : welcome to the world !" customer="" description="Dans cet article je présente la filière de recyclage du mobilier et comment elle illustre la logique de des eco-organismes" moreLink="http://yieldconsumption.com/eco-mobilier-welcome-to-the-world/" />
<Card3 title="Eco-cyclology, Eco-organisms… and politics ! " customer="" description="Dans cet article je décris le fonctionnement des filières de recyclage et le rôle du gouvernement dans leur mise en place" moreLink="http://yieldconsumption.com/eco-cyclology-eco-organisms-and-politics/" />
<Card3 title="Reuse VS Recycle " customer="" description="Dans cet article j'illustre combien la réutilisation est plus prometteuse que le recyclage" moreLink="http://yieldconsumption.com/reuse-vs-recycle/" />
<Card3 title="Spartoo : Soles4souls " customer="" description="Dans cet article je présente une initiative de recyclage mise en place Spartoo " moreLink="http://yieldconsumption.com/spartoo-soles4souls/" />
<Card3 title="Patagonia – the quintessence of yield consumption" customer="" description="Dans cet article je présente Patagonia et les nombreuses initiatives que portent la marque pour intégrer un fonctionnement circulaire à son modèle" moreLink="http://yieldconsumption.com/patagonia-the-quintessence-of-yield-consumption/" />
<Card3 title="Eco-cyclology" customer="" description="Dans cet article, j'introduis l'eco-cylcology et la démarche partagée entre entreprise et gouvernement qu'elle traduit" moreLink="http://yieldconsumption.com/eco-cyclology/" />
<Card3 title="Loewe’s TV-shaped 500€ coupon " customer="" description="Dans cet article, je présente une initiative de réemploi de Loewe " moreLink="http://yieldconsumption.com/loewes-tv-shaped-500e-coupon/" />
<Card3 title="Temporary ownership" customer="" description="Dans cet article j'essayais d'introduire l'idée d'acheter temporairement plutôt que de louer" moreLink="http://yieldconsumption.com/temporary-ownership-concrete-data-from-fellow-students/" />
<Card3 title="Hyundai Trade-in Value garantee " customer="" description="Dans cet article, je présente une initiative de Hiunday pour favoriser la durabilité de ses voitures" moreLink="http://yieldconsumption.com/hyundai-trade-in-value-garantee/" />
<Card3 title="A.P.C Butler / Worn Out Series " customer="" description="Dans cet article, je présente la filière de recyclage mise en place par APC pour ses clients" moreLink="http://yieldconsumption.com/a-p-c-butler-worn-out-series/" />
<Card3 title="Civil society, corporate world and government - Part 3" customer="" description="Dans cet article, je me focalise sur le rôle du gouvernement dans la mise en place de schémas de consommation circulaire" moreLink="http://yieldconsumption.com/civil-society-corporate-world-and-government-%e2%80%93-who-acts-for-the-environment-%e2%80%93-part-3/" />
<Card3 title="Civil Society, Corporate World and Government - Part 2" customer="" description="Dans ce deuxième article, je m'intéresse au rôle des entreprises, et à l'opportunité que représente la consommation circulaire de leur point de vue" moreLink="http://yieldconsumption.com/civil-society-corporate-world-and-government-who-acts-for-the-environment-part2/" />
<Card3 title="Civil Society, Corporate World and Government - Part 1" customer="" description="Dans cette série d'article, je commence par décrire le rôle des consommateur dans la mise en place de schémas de consommation circulaire" moreLink="http://yieldconsumption.com/90/" />
<Card3 title="Two 98% figures that hurt " customer="" description="Dans cet article je commente des chiffres issues de mes lectures qui témoignent de la quantité de déchet générée par notre mode de consommation" moreLink="http://yieldconsumption.com/two-98-figures-that-hurt/" /></Box>
      </Container>
      </Grid>
    </Grid>
  );
}
